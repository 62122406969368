import React, { useState, useEffect } from "react";

const SlideInOnScroll = ({ children, id, delay }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const windowHeight = window.innerHeight;
      const elementOffset = document.getElementById(id).offsetTop;

      if (currentScrollPos > elementOffset - windowHeight * 0.9) {
        // Introduce a time delay before setting isVisible to true
        setTimeout(() => {
          setIsVisible(true);
        }, delay || 0);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id, delay]);

  return (
    <div
      id={id}
      className={`${
        isVisible
          ? "translate-y-0 opacity-100 transition-all duration-1000 ease-out"
          : "translate-y-10 opacity-0"
      }`}
    >
      {children}
    </div>
  );
};

export default SlideInOnScroll;
