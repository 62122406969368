import React from "react";
import { hero } from "../assets/Images";
import "../../../../src/App.css";

const HeroSection: React.FC = () => {
  return (
    <div className="flex mt-10 rounded-[30px] relative flex-col w-full lg:flex-row lg:justify-between space-y-6 lg:space-y-0 text-start justify-between items-center bg-gradient-to-r from-purple-100 to-purple-200 py-6 md:py-16 px-8">
      <div className="pt-2 lg:pt-0 basis-1/2">
        <h1 className="text-gray-700 lg:text-[68px] text-[46px] font-bold leading-tight mb-4">
          Ignite Your Brand's{" "}
          <span className="text-purple-600 italic">Digital Potential</span>
        </h1>
        <p className="text-gray-700 text-[15px] md:text-[18px] mb-8 lg:w-[500px]">
          Elevate your online presence with{" "}
          <span className=" ">Nkhwazi Spark Media's</span> digital marketing
          solutions
        </p>
        <div className="flex space-x-4">
          <a
            href="tel:+260952257464"
            className="bg-purple-600 text-white px-8 py-4 font-medium rounded-full hover:bg-purple-700 transition duration-300 inline-block"
          >
            Let's talk
          </a>
        </div>
      </div>
      <div className="basis-1/2 justify-end">
        <h1 className="text-purple-100 lg:flex hidden text-center lg:text-[120px] text-[76px] font-bold leading-tight mb-4">
          Nkhwazi Spark Media
        </h1>
        <img
          src="https://ik.imagekit.io/haircareai/hero.png?updatedAt=1727444842532"
          loading="lazy"
          className="md:h-[800px]  z-50 absolute lg:top-[-40px] lg:right-[-40px] top-[350px] right-0"
          alt="Digital Marketing Expert"
        />
      </div>
    </div>
  );
};

export default HeroSection;
