import React, { useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../assets/images";

const NavBar = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [activeTab, setActiveTab] = useState(true);

  const navItems = [
    { label: "Home", path: "/" },
    {
      label: "Services",
      path: "#services",
      subItems: [
        { label: "Web Development", path: "/services/web-development" },
        { label: "Mobile Apps", path: "/services/mobile-apps" },
        { label: "UI/UX Design", path: "/services/ui-ux-design" },
      ],
    },
    { label: "About", path: "#about" },
  ];

  return (
    <nav className="bg-white">
      <div className="container">
        <div className="flex justify-between items-center py-4">
          <div>
            <Link to="/">
              <img src={logo} className="h-10" alt="Logo" />
            </Link>
          </div>

          <div className="hidden lg:flex">
            <ul className="flex space-x-16 items-center">
              {navItems.map((item) => (
                <li
                  key={item.path}
                  className="relative"
                  onMouseEnter={() => item.subItems && setShowServices(true)}
                  onMouseLeave={() => item.subItems && setShowServices(false)}
                >
                  <a
                    href={item.path}
                    className={`${
                      item.label === "Home"
                        ? "text-purple-700 font-bold"
                        : "text-gray-300 font-medium"
                    }  hover:text-gray-900 `}
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <a
              href="tel:+260952257464"
              className="bg-gradient-to-r from-purple-100 to-purple-200 text-purple-500 text-[14px] lg:text-[14px] font-medium py-4 px-4 rounded-3xl hover:bg-opacity-90 transition duration-300"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
