import React from "react";
import SlideInOnScroll from "../assets/animations/SlideInScroll";
import { mega } from "../assets/Images";

interface ServiceCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  index: number;
}
const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
  index,
}) => (
  <SlideInOnScroll id={`service-${index}`} delay={index * 100}>
    <div className="bg-purple-600 p-6 py-11 rounded-3xl text-center">
      <div className="flex justify-center mb-4">{icon}</div>
      <h3 className="text-white text-[14px] lg:text-xl font-semibold mb-2">
        {title}
      </h3>
      <p className="text-purple-200 text-[15px] md:text-[18px]">
        {description}
      </p>
    </div>
  </SlideInOnScroll>
);
interface Service {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const BenefitSection: React.FC = () => {
  const services: Service[] = [
    {
      title: "Digital Marketing",
      description:
        "We help you maximize your reach and impact, driving traffic and leads. From campaigns to content marketing.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
          />
        </svg>
      ),
    },
    {
      title: "Development",
      description:
        "We create stunning, user-friendly websites that not only captivate your audience but also align with your brand identity.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
      ),
    },

    {
      title: "Analytics and Data Insights",
      description:
        "We track and analyze key metrics to help you make informed decisions, improve user experiences, and achieve your business goals.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 6a7.5 7.5 0 1 0 7.5 7.5h-7.5V6Z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13.5 10.5H21A7.5 7.5 0 0 0 13.5 3v7.5Z"
          />
        </svg>
      ),
    },
    {
      title: "Content Creation",
      description:
        "We craft engaging and high-quality content that resonates with your audience, boosts your SEO, and establishes your brand as an industry leader.",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-12 h-12 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
          />
        </svg>
      ),
    },
  ];

  return (
    <section id="services" className=" py-16 px-2 mt-[200px]">
      <div>
        <div className=" h-full flex w-full items-end justify-center">
          <div>
            <SlideInOnScroll id="22" delay={100}>
              <h1 className="text-purple-100 text-center lg:text-[120px] text-[66px] font-bold leading-tight mb-4">
                Nkhwazi Spark Media
              </h1>
            </SlideInOnScroll>
          </div>
        </div>
      </div>
      <div className=" text-center mt-4 mb-12">
        <SlideInOnScroll id="2232" delay={100}>
          <button className=" text-purple-500 underline text-[24px] md:text-[28px] px-8 py-4 font-bold  rounded-full hover:bg-purple-700 transition duration-300">
            Our Services
          </button>
        </SlideInOnScroll>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-11">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} index={index} />
        ))}
      </div>
      <SlideInOnScroll id="223256" delay={100}>
        <h1 className="text-purple-100 text-center mt-7 lg:mt-16 lg:text-[120px] text-[76px] font-bold leading-tight mb-4">
          Innovative Digital Marketing
        </h1>{" "}
      </SlideInOnScroll>
      <div>
        <SlideInOnScroll id="22325" delay={100}>
          <img
            src="https://ik.imagekit.io/haircareai/Untitled%20design%20(11).png?updatedAt=1727444841840"
            alt="Nkhwazi Spark Media Team"
            className="lg:hidden flex"
          />
        </SlideInOnScroll>
      </div>{" "}
      <div className=" text-center">
        <SlideInOnScroll id="223253" delay={100}>
          <button className=" text-purple-500 underline text-[24px] md:text-[28px] px-8  font-bold  rounded-full hover:bg-purple-700 transition duration-300">
            About Us
          </button>
        </SlideInOnScroll>
      </div>
    </section>
  );
};

export default BenefitSection;
