import Footer from "../../components/footer/Footer";
import NavBar from "../../components/navbar/NavBar";
import About from "./components/About";
import BenefitSection from "./components/BenefitSection";
import FAQSection from "./components/FAQSection";
import HeroSection from "./components/HeroSection";

const Home = () => {
  return (
    <div>
      <div className="mx-3 lg:mx-20">
        <section>
          <NavBar />
        </section>
        <section>
          <HeroSection />
        </section>
        <section>
          <BenefitSection />
        </section>
        <section>
          <About />
        </section>
        <section>
          <FAQSection />
        </section>
      </div>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default Home;
