import React, { useState } from "react";
import SlideInOnScroll from "../assets/animations/SlideInScroll";

const FAQSection = () => {
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prevOpenAccordions) => {
      if (prevOpenAccordions.includes(index)) {
        return prevOpenAccordions.filter((i) => i !== index);
      } else {
        return [...prevOpenAccordions, index];
      }
    });
  };

  const faqData = [
    {
      question:
        "What sets Nkhwazi Spark Media apart from other digital marketing agencies?",
      answer:
        "At Nkhwazi Spark Media, we focus on personalized, data-driven strategies that align with your specific business goals. Our unique blend of creativity, innovation, and analytical thinking ensures your brand not only stands out but also delivers measurable results.",
    },
    {
      question: "Do you work with businesses of all sizes?",
      answer:
        "Absolutely! Whether you're a startup, a small business, or an established brand, we tailor our services to fit your needs and budget. We believe that every brand has the potential to grow with the right digital strategy.",
    },
    {
      question: "What is your approach to creating a marketing strategy?",
      answer:
        "We begin by understanding your business, industry, target audience, and goals. From there, we conduct thorough market research and competitor analysis to develop a tailored strategy. We focus on creating content, targeting the right channels, and constantly optimizing campaigns to maximize results.",
    },
    {
      question: "How do we get started with Nkhwazi Spark Media?",
      answer:
        "It's simple! Reach out to us through our contact page, and we'll schedule a free consultation to discuss your business needs and goals. From there, we'll develop a customized plan and get your campaign off the ground!",
    },
  ];

  return (
    <div className="flex mt-8 flex-col justify-center items-center lg:my-[60px] w-full mb-6">
      <div>
        <SlideInOnScroll id="10102" delay={100}>
          <div className=" text-center">
            <button className=" text-purple-600 text-[24px] md:text-[28px] px-8 py-4 font-bold  rounded-full hover:bg-purple-700 transition duration-300">
              Frequently Asked Questions
            </button>
          </div>
        </SlideInOnScroll>
      </div>

      <div className="w-full lg:mt-[70px] mt-[1px] lg:px-20">
        <SlideInOnScroll id="101023" delay={100}>
          <div className="flex flex-col">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="border-b border-gray-300 last:border-none"
              >
                <div
                  className="py-6 cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div>
                      <h1 className="font-semibold text-[16px] text-gray-700">
                        {faq.question}
                      </h1>
                    </div>
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`size-6 transition-transform ${
                          openAccordions.includes(index) ? "rotate-45" : ""
                        }`}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </div>
                  </div>
                  {openAccordions.includes(index) && (
                    <div className="pt-4">
                      <p className="text-gray-500 text-[15px]">{faq.answer}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </SlideInOnScroll>
      </div>
    </div>
  );
};

export default FAQSection;
