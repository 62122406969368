import { Link } from "react-router-dom";
import {
  facebook,
  instagram,
  linkedin,
  logo,
  logoWhite,
  tiktok,
  x,
} from "../../assets/images";

const socialLinks = [
  {
    href: "https://www.linkedin.com/in/cleopas-mwape-9855211a3/",
    icon: linkedin,
    alt: "LinkedIn",
  },
  {
    href: "https://www.facebook.com/profile.php?id=61566889663806",
    icon: facebook,
    alt: "Facebook",
  },
];

const Footer = () => {
  return (
    <div className="lg:h-[300px] h-[150px] w-full flex justify-center items-end pb-3 bg-purple-600">
      <div className="mx-3 lg:mx-20 pt-[60px] flex flex-col space-y-8 justify-center items-center">
        <div className="flex flex-row space-x-8 lg:space-x-20">
          {socialLinks.map(({ href, icon, alt }) => (
            <a
              key={alt}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={alt}
            >
              <img src={icon} className="h-[20px] md:h-[20px]" alt={alt} />
            </a>
          ))}
        </div>
        <div className="pt-5">
          <p className="text-center text-[12px] text-white">
            Nkhwazi Spark Media © 2024. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
