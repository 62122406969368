import React from "react";
import { mega } from "../assets/Images";

const About: React.FC = () => {
  return (
    <section
      id="about"
      className="bg-gradient-to-r from-purple-50 to-purple-100 rounded-3xl py-16 px-8"
    >
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row items-center gap-12">
          <div className="md:w-1/2 hidden lg:flex">
            <img src={mega} alt="Nkhwazi Spark Media Team" className="" />
          </div>
          <div className="md:w-1/2">
            <p className="text-gray-700 text-[15px] lg:text-lg mb-6">
              At{" "}
              <span className="text-purple-600 font-bold">
                Nkhwazi Spark Media
              </span>
              , we're passionate about igniting your brand's digital potential.
              Our team of expert marketers and developers work tirelessly to
              elevate your online presence and drive meaningful results for your
              business.
            </p>
            <p className="text-gray-700 text-[15px] lg:text-lg mb-6">
              Founded with a vision to{" "}
              <span className="text-purple-600 font-bold">
                empower businesses
              </span>{" "}
              in the digital landscape, we combine creativity, strategy, and
              cutting-edge technology to deliver tailored solutions that make
              your brand shine.
            </p>
            <p className="text-gray-700 text-[15px] lg:text-lg">
              From{" "}
              <span className="text-purple-600 font-bold">
                digital marketing
              </span>{" "}
              campaigns that captivate your audience to stunning websites that
              convert visitors into customers, we're here to be your partner in
              digital success.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
